<template>

    <SmartLinkItemComponent custom-tag="div" class="banner" :class="lightBackgroundClass" v-if="model"
        :id="`_${model.system.id}`" :component="!model.system.workflow">
        <div class="container" :class="darkBackgroundClass">
            <div class="row">
                <div class="col-lg-4" :class="imagePlacementClass"
                    v-if="firstOrDefault(model?.elements?.image?.value)?.url">
                    <SmartLinkStandaloneElement custom-tag="div" class="image" :codename="type.elements.image.codename">
                        <NuxtImg class="img-fluid" provider="kontentAiProvider" v-for="img in data.images"
                            :src="img.url"></NuxtImg>
                    </SmartLinkStandaloneElement>
                </div>
                <div class="col-lg">
                    <SmartLinkStandaloneElement custom-tag="h5" class="eyebrow" v-if="data.eyebrow"
                        :codename="type.elements.eyebrow.codename">
                        {{ data.eyebrow }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="h3" class="title" v-if="data.title"
                        :codename="type.elements.title.codename">
                        {{ data.title }}
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="div" class="description" v-if="!isEmpty(data.description)"
                        :codename="type.elements.description.codename">
                        <RichText :value="data.description" />
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="div" class="cta"
                        :codename="type.elements.call_to_action.codename">
                        <CallToAction custom-class="btn btn-primary btn-sm" v-if="data.ctas" v-for="item in data.ctas"
                            :model="item" />
                    </SmartLinkStandaloneElement>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>

</template>

<script lang="ts" setup>
import { type CallToActionModel, type BannerModel, contentTypes } from '~/models';
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';

const pageStore = usePageStore();
const props = defineProps<{ model: BannerModel }>();
const type = contentTypes.banner;


const data = computed(() => {
    return {
        eyebrow: props.model?.elements?.eyebrow?.value,
        title: props.model?.elements?.title?.value,
        description: props?.model?.elements?.description?.value,
        ctas: pageStore.getListAs<CallToActionModel>(props?.model?.elements?.callToAction?.value),
        images: props?.model?.elements?.image?.value ?? [],
        layout: firstOrDefault(props?.model?.elements?.layout?.value)?.codename,
        imagePlacement: firstOrDefault(props?.model?.elements?.imagePlacement?.value)?.codename,
        design: firstOrDefault(props?.model?.elements?.design?.value)?.codename,
        anchorTagNavigationMenuTitle: props.model.elements.anchorTagNavigationMenuTitle.value,
        anchorTagNavigationShowInNavigation: firstOrDefault(props?.model?.elements?.anchorTagNavigationShowInNavigation?.value)
    }
});
const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder');

if (multipleChoiceToBoolean(props.model.elements.anchorTagNavigationShowInNavigation)) {

    navBarState.value.push({ text: props.model.elements.anchorTagNavigationMenuTitle.value, link: `#${props.model.system.id}`, index: AnchorNavBarOrder.value.indexOf(props.model.system.codename)});
}

const darkBackgroundClass = computed(() => {
    return data.value.design == 'dark' ? 'bg-dark-blue' : ''
})

const lightBackgroundClass = computed(() => {
    return data.value.design == 'light' ? 'bg-light-blue' : ''
})

const containerClass = computed(() => {
    if (data.value.layout == 'container') {
        return 'container';
    } else if (data.value.layout == 'full_width') {
        return 'container-fluid';
    } else {
        return 'container';
    }
})

const imagePlacementClass = computed(() => {
    return data.value.imagePlacement == 'right' ? 'order-lg-2 order-1' : ''
})
</script>